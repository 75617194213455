import React from 'react';
import PropTypes from 'prop-types';

import { H1 } from '../../styles/commonStyles';
import { colors } from '../../styles/variables';

import { StyledContainer, Article, StyledParagraph, StyledPicture } from './Post.style';

const propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
  }).isRequired,
};

const Post = ({ title, featuredImage, content }) => (
  <StyledContainer column justifyCenterTablet>
    <Article>
      <H1 color={colors.darkShade}>{title}</H1>
      {featuredImage && <StyledPicture fluid={featuredImage.localFile.childImageSharp.fluid} />}
      <StyledParagraph as="div" dangerouslySetInnerHTML={{ __html: content }} />
    </Article>
  </StyledContainer>
);

Post.propTypes = propTypes;

export default Post;
