import Styled from 'styled-components';

import { colors, breakpoints } from '../../styles/variables';
import { Paragraph } from '../../styles/commonStyles';
import { ButtonStyle } from '../Button/Button.style';
import Picture from '../Picture/Picture';

import Container from '../Container/Container';

export const StyledContainer = Styled(Container)`
  padding-top: 20rem;

  .wp-block-file__button {
    ${ButtonStyle};

    margin: 2rem 0;
  }

  .wp-block-image {
    margin: 2rem 0;
  }

  a {
    color: ${colors.darkShade};

    :hover {
      text-decoration: underline;
    }
  }
`;

export const StyledParagraph = Styled(Paragraph)`
  padding: 2rem;

  img {
    width: 100%;
  }
`;

export const Article = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90%;

  @media ${breakpoints.tabletLandscape} {
    max-width: 60%;
  }
`;

export const StyledPicture = Styled(Picture)`
  width: 100%;
  max-width: 70%;
  margin-top: 5rem;
`;
