/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import Post from '../../components/Post/Post';
import PostQuery from './post.query';

const propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const NewsPost = ({ pageContext }) => {
  const { id } = pageContext;

  return (
    <PostQuery id={id}>
      {({ node }) => {
        const { title, date, featured_media, content } = node;
        return (
          <Post
            id={id}
            title={title}
            date={date}
            featuredImage={featured_media}
            content={content}
          />
        );
      }}
    </PostQuery>
  );
};

NewsPost.propTypes = propTypes;

export default NewsPost;
