import React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from 'gatsby';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func])
    .isRequired,
  id: PropTypes.string.isRequired,
};

const PostQuery = ({ children, id }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost {
          edges {
            node {
              content
              id
              slug
              title
              featured_media {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allWordpressPost }) =>
      children(allWordpressPost.edges.find(({ node }) => node.id === id || null))
    }
  />
);

PostQuery.propTypes = propTypes;

export default PostQuery;
